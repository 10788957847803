import { ExperimentName } from '@/experiments/experimentName'

// Some typescript magic: this enforces that ALL keys of ExperimentName have an associated key
// This makes sure we don't have any Experiments that are missing strings 😄
export const ENGLISH_TRANSLATIONS: { experiments: { [T in ExperimentName]: {} } } = {
    experiments: {
        [ExperimentName.cryptoDefault]: {
            footer: `
                <p id="footnote_1">1. APR (Annual Percentage Rate) is the cost of credit as a yearly rate and does not include costs other than interest. It is a variable rate based on the Prime Rate plus a margin that doesn't change. The Prime Rate is the highest prime rate published in The Wall Street Journal "Money Rates" table (the "Index"). The Index as of August 3, 2021 is 3.25%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account. Cash Out (draw to bank account) fee and Balance Transfer fee is 2% of amount transferred.</p>
                <p>Cashback applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit. Refer to Rewards Terms & Conditions.</p>
                <p id="footnote_2">2. Refer to Referral Terms & Conditions.</p>
            `,
        },
    },
}
