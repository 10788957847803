import { RouteConfig } from 'vue-router'
import { marketingPageNames, marketingPagePaths } from '@/routes/marketingRoutes'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { ExperimentName } from '@/experiments/experimentName'

export const defaultPagePaths = {
    DEFAULT: '/',
    DEFAULT_JOIN: '',
}

// TODO: base and join should really be next to each other IMO, should reduce mistakes
export const experimentPageNames = {
    // base names
    JOIN_AVEN: 'joinaven',
    AVEN_CREDIT: 'avencredit',
    GO_AVEN: 'goaven',
    TRY_AVEN: 'tryaven',
    MEET_AVEN: 'meetaven',
    VISIT_AVEN: 'visitaven',
    FIND_AVEN: 'findaven',
    DISCOVER_AVEN: 'discoveraven',
    SALUTATIONS_AVEN: 'salutationsaven',
    AVEN_FINANCE: 'avenfinance',
    WITH_AVEN: 'withaven',
    BANKRATE: 'bankrate',
    DEBT: 'debt',

    // join names
    JOIN_AVEN_JOIN: 'joinavenjoin',
    AVEN_CREDIT_JOIN: 'avencreditjoin',
    GO_AVEN_JOIN: 'goavenjoin',
    TRY_AVEN_JOIN: 'tryavenjoin',
    MEET_AVEN_JOIN: 'meetavenjoin',
    VISIT_AVEN_JOIN: 'visitavenjoin',
    FIND_AVEN_JOIN: 'findavenjoin',
    DISCOVER_AVEN_JOIN: 'discoveravenjoin',
    SALUTATIONS_AVEN_JOIN: 'salutationsavenjoin',
    AVEN_FINANCE_JOIN: 'avenfinancejoin',
    WITH_AVEN_JOIN: 'withavenjoin',
    BANKRATE_JOIN: 'bankratejoin',
    DEBT_JOIN: 'debtjoin',
}

export const experimentPageBasePaths = {
    JOIN_AVEN: `/${experimentPageNames.JOIN_AVEN}`,
    AVEN_CREDIT: `/${experimentPageNames.AVEN_CREDIT}`,
    GO_AVEN: `/${experimentPageNames.GO_AVEN}`,
    MEET_AVEN: `/${experimentPageNames.MEET_AVEN}`,
    TRY_AVEN: `/${experimentPageNames.TRY_AVEN}`,
    VISIT_AVEN: `/${experimentPageNames.VISIT_AVEN}`,
    FIND_AVEN: `/${experimentPageNames.FIND_AVEN}`,
    DISCOVER_AVEN: `/${experimentPageNames.DISCOVER_AVEN}`,
    SALUTATIONS_AVEN: `/${experimentPageNames.SALUTATIONS_AVEN}`,
    AVEN_FINANCE: `/${experimentPageNames.AVEN_FINANCE}`,
    WITH_AVEN: `/${experimentPageNames.WITH_AVEN}`,
    BANKRATE: `/${experimentPageNames.BANKRATE}`,
    DEBT: `/${experimentPageNames.DEBT}`,
} as const
export type ExperimentBasePathKeys = keyof typeof experimentPageBasePaths
export type ExperimentBasePaths = typeof experimentPageBasePaths[ExperimentBasePathKeys] | typeof defaultPagePaths[keyof typeof defaultPagePaths]

export const experimentPageJoinPaths = {
    JOIN_AVEN_JOIN: `/${experimentPageNames.JOIN_AVEN}/join`,
    AVEN_CREDIT_JOIN: `/${experimentPageNames.AVEN_CREDIT}/join`,
    GO_AVEN_JOIN: `/${experimentPageNames.GO_AVEN}/join`,
    MEET_AVEN_JOIN: `/${experimentPageNames.MEET_AVEN}/join`,
    TRY_AVEN_JOIN: `/${experimentPageNames.TRY_AVEN}/join`,
    VISIT_AVEN_JOIN: `/${experimentPageNames.VISIT_AVEN}/join`,
    FIND_AVEN_JOIN: `/${experimentPageNames.FIND_AVEN}/join`,
    DISCOVER_AVEN_JOIN: `/${experimentPageNames.DISCOVER_AVEN}/join`,
    SALUTATIONS_AVEN_JOIN: `/${experimentPageNames.SALUTATIONS_AVEN}/join`,
    AVEN_FINANCE_JOIN: `/${experimentPageNames.AVEN_FINANCE}/join`,
    WITH_AVEN_JOIN: `/${experimentPageNames.WITH_AVEN}/join`,
    BANKRATE_JOIN: `/${experimentPageNames.BANKRATE}/join`,
    DEBT_JOIN: `/${experimentPageNames.DEBT_JOIN}/join`,
} as const
export type ExperimentJoinPathKeys = keyof typeof experimentPageJoinPaths
export type ExperimentJoinPaths = typeof experimentPageJoinPaths[ExperimentJoinPathKeys] | typeof defaultPagePaths[keyof typeof defaultPagePaths]

export const experimentPagePaths = {
    // experiment page base paths
    ...experimentPageBasePaths,

    // experiment page join paths
    ...experimentPageJoinPaths,
}

const CryptoLanding = () => import(/* webpackChunkName: "crypto-landing" */ '@/experiments/src/pages/marketing/CryptoLanding.vue')

const experimentName = appSessionStorage.getItem(localStorageKey.experimentName)
const getDefaultLandingPageLazyLoad = () => {
    switch (experimentName) {
        case ExperimentName.cryptoDefault:
        default:
            return CryptoLanding
    }
}

const landingPageRoutes: RouteConfig[] = [
    {
        path: marketingPagePaths.LANDING,
        name: marketingPageNames.LANDING,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: true },
        meta: { public: true },
    },
    {
        path: marketingPagePaths.LANDING_JOIN,
        name: marketingPageNames.LANDING_JOIN,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: true },
        meta: { public: true },
    },
]

// TODO: Why do we have a bunch of paths again? Makes life really complicated
const landingExperimentPageRoutes: RouteConfig[] = []

export const experimentMarketingPageRoutes: RouteConfig[] = [...landingPageRoutes, ...landingExperimentPageRoutes]
