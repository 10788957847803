import assert from 'assert'
import { ExperimentName } from '@/experiments/experimentName'
import { HeraclesParameter } from '@/experiments/heraclesParameter'
import { CryptoDefault } from '@/experiments/cryptoDefault/cryptoDefault'

const experimentToPolicyMap: { [T in ExperimentName]: HeraclesParameter } = {
    [ExperimentName.cryptoDefault]: new CryptoDefault(),
}

// Not foolproof, but helps try to catch missing experiment image folders before they make it to prod
for (const expPolicy of Object.values(experimentToPolicyMap)) {
    try {
        require(`@/assets/images/experiments/${expPolicy.EXPERIMENT_NAME}/index.ts`)
    } catch (e) {
        console.error(`Looks like you're missing the @/assets/images/experiments/${expPolicy.EXPERIMENT_NAME} folder!`)
        throw e
    }
}

export const getPolicyForExperiment = (exp: ExperimentName): HeraclesParameter => {
    assert(exp, 'Missing experiment name!')

    return experimentToPolicyMap[exp]
}
