import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

const DISPLAY_DATE_FORMAT = 'YYYY-MM-DDThh:mm:ss.SSSZ'

export function calculateAge(dob: string) {
    const today = new Date()
    const birthDate = new Date(dob)
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1
    }

    return age
}

export function getCurrentTimezone(): string {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    return dayjs.tz.guess()
}

// Taken from aven-my
export function convertDateTimeFormat(inputDateTime: string | Date, inputTimezone: string, outputTimezone: string, outputFormat: string = DISPLAY_DATE_FORMAT): string | null {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    if (inputDateTime === null) {
        return null
    }

    return dayjs.tz(inputDateTime, inputTimezone).tz(outputTimezone).format(outputFormat)
}

// Latest tax year is two years ago for Jan-Apr and one year ago for May-Dec
export function getLatestTaxYear(): number {
    const today = new Date()
    // 3 = April since months are zero indexed in Node
    return today.getMonth() > 3 ? today.getFullYear() - 1 : today.getFullYear() - 2
}
