import { ExperimentName } from '@/experiments/experimentName'
import { ExperimentBasePaths, ExperimentJoinPaths } from '@/experiments/src/routes/marketingRoutes'

export class GlobalParameters {
    /*** THESE PARAMETERS DO NOT VARY BY POLICY ***/

    // Default Redemption ratio is 1x (i.e., 1 point = 1 cent)
    // If redeeming points at, say, 1.5x use 0.015 for this parameter
    public BASIC_CASH_BACK_REDEMPTION_POINTS_TO_DOLLAR_RATIO = 0.015
    public PRE_QUALIFICATION_LOW_APR_LIMIT = 0.0749
}

// from: https://docs.google.com/spreadsheets/d/1LXEWQyMq8vHU3nOjHqG-XoJhkB3umgSCkF4o4piVJYY/edit#gid=0
export abstract class HeraclesParameter extends GlobalParameters {
    /*** THESE PARAMETERS MUST VARY BY POLICY ***/

    public abstract EXPERIMENT_NAME: ExperimentName
    public abstract EXPERIMENT_BASE_PATH: ExperimentBasePaths
    public abstract JOIN_PATH: ExperimentJoinPaths

    // Dollars charged to card upon finishing account open
    public ORIGINATION_FEE = 0.0

    // Balance transfer fee percentage
    public BALANCE_TRANSFER_FEE_PERCENT = 0.0
}
