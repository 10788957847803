import { HeraclesParameter } from '@/experiments/heraclesParameter'
import { ExperimentName } from '@/experiments/experimentName'
import { defaultPagePaths } from '@/experiments/src/routes/marketingRoutes'

export class CryptoDefault extends HeraclesParameter {
    EXPERIMENT_NAME = ExperimentName.cryptoDefault
    // @ts-ignore
    EXPERIMENT_BASE_PATH = defaultPagePaths.DEFAULT
    // @ts-ignore
    JOIN_PATH = defaultPagePaths.DEFAULT_JOIN
}
