import Vue from 'vue'
import VueRouter, { Route, NavigationGuard } from 'vue-router'
import VueGtag from 'vue-gtag'
import VueMeta from 'vue-meta'
import { authRoutes } from '@/routes/authRoutes'
import { logger, inspect } from '@/utils/logger'
import { sharedPagePaths, sharedRoutes } from '@/routes/sharedRoutes'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { marketingPagePaths, marketingPageRoutes } from '@/routes/marketingRoutes'
import { experimentMarketingPageRoutes, experimentPagePaths } from '@/experiments/src/routes/marketingRoutes'
import { originationRoutes } from '@/routes/originationRoutes'
import { originationEntryPagePaths, originationBackGuardPagePaths } from '@/flow/originationFlow'
import { checkPathsMatch, RouteOption } from '@/flow/flowUtility'
import { getNextPath, latestPath } from '@/flow/flowController'
import { currentContextForLogging } from '@/main'
import { isSafariPrivateBrowsing } from '@/utils/parseUserAgents'
import { getPolicyForExperiment } from '@/experiments/getPolicyForExperiment'
import { ExperimentName } from '@/experiments/experimentName'
import { isEmpty } from 'lodash'

const DISABLE_NAVIGATION_GUARDS = false // disables redirects so components can be iterated on more quickly. don't forget to set back to false.
let NAVIGATED_ONCE = false // some components will redirect you manually. this gets set to true automatically in the beforeEach hook below.

Vue.use(VueRouter)
Vue.use(VueMeta, {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    tagIDKeyName: 'vmid',
    refreshOnceOnNavigation: true,
})

const routes = [...marketingPageRoutes, ...experimentMarketingPageRoutes, ...authRoutes, ...sharedRoutes, ...originationRoutes]

const landingPagePaths = [...originationEntryPagePaths]
const oldLandingPagePathPairs = [
    ['secret', marketingPagePaths.LANDING],
    ['secretjoin', marketingPagePaths.LANDING_JOIN],
]

const backGuardPagePaths = [...originationBackGuardPagePaths]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: function (to: Route) {
        if (to.hash) {
            return { selector: to.hash }
        } else {
            return { x: 0, y: 0 }
        }
    },
})

if (['production', 'staging', 'uat', 'test'].includes(process.env.VUE_APP_NODE_ENV)) {
    Vue.use(
        VueGtag,
        {
            config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_TAG },
        },
        router
    )
}

router.onError((error: any) => {
    logger.info(`router error: ${inspect(error)}`)
    // See: https://blog.francium.tech/vue-lazy-routes-loading-chunk-failed-9ee407bbd58
    if (/Loading.*chunk.*failed./i.test(error.message)) {
        logger.info('Reloading page to fix stale chunk error')
        return window.location.reload(true)
    }

    throw error
})

router.afterEach(() => {
    try {
        for (const member in currentContextForLogging) {
            delete currentContextForLogging[member]
        }
    } catch (e) {
        logger.fatal(`error clearing current context for logging`, null /* event */, e)
    }
})

router.beforeEach((to: Route, from: Route, next: Function) => {
    if (DISABLE_NAVIGATION_GUARDS) {
        if (NAVIGATED_ONCE) {
            return next(false)
        }
        NAVIGATED_ONCE = true
        return next()
    }

    logger.info(`routing from: ${from.path} to: ${to.path}`)
    window.previousPath = from.path

    for (const [oldPath, newPath] of oldLandingPagePathPairs) {
        if (checkPathsMatch(oldPath, to.path)) {
            logger.error(`User navigating to a deprecated page ${oldPath}, redirecting to ${newPath}`)
            window.location.href = newPath
            return
        }
    }

    // remove any modals if needed
    document.body.classList.remove('modal-open')
    document.getElementById('modal-backdrop')?.remove()

    // from null to ["/", "/join", etc...], users loads our site
    // we store the starting page path and reset localStorageKey.clearStorageOnNavigation
    if (landingPagePaths.findIndex((path) => checkPathsMatch(path, to.path)) >= 0) {
        logger.info(`saved start page path in session storage, ${to.path}`)
        appSessionStorage.setItem(localStorageKey.startPagePath, to.path)
    }

    let navigatedEarly

    navigatedEarly = experimentRedirectCheck(to, from)
    if (navigatedEarly) {
        return
    }

    // clear storage if clearStorageOnNavigation is present in storage
    navigatedEarly = clearStorageCheck(to)
    if (navigatedEarly) {
        return
    }

    // jwt token required for all paths that are not public
    navigatedEarly = authCheck(to)
    if (navigatedEarly) {
        return
    }

    // prevent users from navigating back on certain guard pages
    navigatedEarly = backGuardCheck(to, from, next)
    if (navigatedEarly) {
        return
    }

    return next()
})

export default router

const handleRedirectCheckError = (to: Route, from: Route, e: any) => {
    if (e.name === 'NavigationDuplicated') {
        // We get NavigationDuplicated errors when the applicant is in an experiment and clicks
        // the 'Card' navigation item. At that point, we try to route to '/', but the experimentRedirectCheck()
        // re-routes from '/' back to the experiment base path again, thus throwing the error.
        logger.info(`NavigationDuplicated error routing from ${from.path} to ${to.path}. This is benign since it's a no-op Vue Router.`)
    } else {
        throw e
    }
}

const experimentRedirectCheck = (to: Route, from: Route) => {
    const experimentName = appSessionStorage.getItem(localStorageKey.experimentName)
    const heraclesParameter = getPolicyForExperiment(experimentName as ExperimentName)

    if (!heraclesParameter || !heraclesParameter.EXPERIMENT_BASE_PATH) {
        logger.info(`User is missing experiment name or base path. authCheck will determine private browsing.`)
        return false
    }

    // If the user is in a non-default experiment, but they are trying to navigate to the default
    // experiment's path, redirect them to their experiment's path.
    if (to.path === '/' && heraclesParameter.EXPERIMENT_BASE_PATH !== to.path) {
        logger.info('User landed on home page with non-default experiment, redirecting to experiment landing page')
        window.logEvent('redirect_experiment_landing_page', {
            query: window.location.search,
            from: from.path,
            to: to.path,
            referrer: heraclesParameter.EXPERIMENT_BASE_PATH,
        })
        router.replace({ path: heraclesParameter.EXPERIMENT_BASE_PATH, query: to.query }).catch((e) => handleRedirectCheckError(to, from, e))
        return true
    }

    // User landed on a <experimentName>/* (e.g. discoveraven/join) that includes an experiment but is not part of
    // the experiment that was in the referrer or is in the default experiment. e.g. they can technically land on
    // /avencredit/* where the referrer is avencredit and try to type in /goaven/*, so we'll redirect them to the correct path
    const isPathAnExperiment = Object.values(experimentPagePaths).includes(to.path as typeof experimentPagePaths[keyof typeof experimentPagePaths])
    const incorrectExperimentBasePath = !to.path.includes(heraclesParameter.EXPERIMENT_BASE_PATH)
    const isDefaultExperiment = heraclesParameter.EXPERIMENT_NAME === ExperimentName.cryptoDefault
    if (isPathAnExperiment && (incorrectExperimentBasePath || isDefaultExperiment)) {
        logger.info('User landed here without a referrer and incorrect experiment path, redirecting to correct path')
        window.logEvent('redirect_incorrect_landing_page', {
            query: window.location.search,
            from: from.path,
            to: to.path,
            referrer: heraclesParameter.EXPERIMENT_BASE_PATH,
        })
        router.replace({ path: heraclesParameter.EXPERIMENT_BASE_PATH }).catch((e) => handleRedirectCheckError(to, from, e))
        return true
    }
    return false
}

const clearStorageCheck = (to: Route) => {
    if (appSessionStorage.getItem(localStorageKey.clearStorageOnNavigation) !== 'true') {
        return false
    }

    if (process.env.VUE_APP_NODE_ENV !== 'production') {
        alert(
            'WARNING: Clear storage on navigation ignored on dev! Your storage would be cleared on prod! Be sure this is intended behavior.\nClearing the clearStorageOnNavigation key from storage now...'
        )
        appSessionStorage.removeItem(localStorageKey.clearStorageOnNavigation)
        return false
    }

    // clear storage will remove jwt token which will force user back to start page
    const startPagePath = appSessionStorage.getItem(localStorageKey.startPagePath) || '/'
    logger.info(`user wants to navigate when application process has ended. clearing storage and navigating to front page if necessary ${startPagePath}`)
    appSessionStorage.clear()

    if (to.matched.some((record) => record.meta.public)) {
        // preserve search params when navigating to non-public pages while forcing a reload to acquire a new session
        let searchParams = window.location.search
        if (!searchParams && !isEmpty(to.query)) {
            const query = Object.assign({}, to.query as any)
            searchParams = '?' + new URLSearchParams(query).toString()
        }
        window.location.href = to.path
        if (searchParams) {
            window.location.search = searchParams
        }
    } else {
        window.location.href = startPagePath
    }
    return true
}

const authCheck = (to: Route) => {
    if (appSessionStorage.getItem(localStorageKey.jwtTokens) || to.matched.some((record) => record.meta.public)) {
        return false
    }

    if (isSafariPrivateBrowsing()) {
        router.push({ path: sharedPagePaths.THANKS, query: { reason: 'privateBrowsing' } })
        return true
    }

    // force back to start page if there is no jwt
    const startPagePath = appSessionStorage.getItem(localStorageKey.startPagePath) || '/'
    logger.info(`next path is ${startPagePath}`)
    window.location.href = startPagePath
    return true
}

const backGuardCheck = (to: Route, from: Route, next: Function) => {
    if (checkPathsMatch(latestPath, to.path)) {
        return false
    }

    for (const backGuardPagePath of backGuardPagePaths) {
        if (!checkPathsMatch(backGuardPagePath, from.path)) {
            continue
        }

        for (const option of [undefined, ...Object.values(RouteOption)]) {
            if (checkPathsMatch(from.path, getNextPath(to.path, option))) {
                if (process.env.VUE_APP_NODE_ENV === 'production') {
                    logger.info(`navigation to ${to.path} from ${from.path} aborted by back guard`)
                    next(false)
                    return true
                } else {
                    alert('WARNING: Backguard ignored. This navigation would be aborted on prod! Be sure this is intended behavior.')
                    return false
                }
            }
        }
        break
    }
    return false
}
