import { RouteConfig } from 'vue-router'

export const marketingPageNames = {
    LANDING: 'landing',
    LANDING_JOIN: 'join',
    ABOUT: 'about',
    CONTACT: 'contact',
    PRIVACY: 'privacy',
    MOBILE_APPS: 'app',
}

export const marketingPagePaths = {
    LANDING: `/`,
    LANDING_JOIN: `/${marketingPageNames.LANDING_JOIN}`,
    ABOUT: `/${marketingPageNames.ABOUT}`,
    PRIVACY: `/${marketingPageNames.PRIVACY}`,
    MOBILE_APPS: `/${marketingPageNames.MOBILE_APPS}`,
    CONTACT: `/${marketingPageNames.CONTACT}`,
}

const infoPageRoutes: RouteConfig[] = [
    {
        path: marketingPagePaths.ABOUT,
        name: marketingPageNames.ABOUT,
        component: () => import(/* webpackChunkName: "about" */ '@/pages/marketing/About.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.PRIVACY,
        name: marketingPageNames.PRIVACY,
        component: () => import(/* webpackChunkName: "privacy" */ '@/pages/marketing/Privacy.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.CONTACT,
        name: marketingPageNames.CONTACT,
        component: () => import(/* webpackChunkName: "contact" */ '@/pages/marketing/Contact.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.MOBILE_APPS,
        name: marketingPageNames.MOBILE_APPS,
        component: () => import(/* webpackChunkName: "mobileApps" */ '@/pages/marketing/MobileApps.vue'),
        meta: { public: true },
    },
]

export const marketingPageRoutes: RouteConfig[] = [...infoPageRoutes]
