import { ExperimentName } from '@/experiments/experimentName'
import { logger } from '@/utils/logger'

// v-experiments take a string or array of strings related to ExperimentName
// render the element if it's part of the experiment, otherwise remove it from the dom
export default {
    bind: function (el: any, binding: any, vnode: any) {
        const removeChild = document.createComment(' ') // creates empty dom element
        if (!binding.value) {
            console.warn('experiments directive requires a value')
        } else if (typeof binding.value === 'string') {
            // can be a single experiment string
            for (const name in ExperimentName) {
                if (name !== binding.value) {
                    logger.log('string value not included, removing from DOM')
                    // remove from DOM if binding value is not in experiment name
                    vnode.elm.parentElement.removeChild(vnode.elm)
                }
            }
        } else if (Array.isArray(binding.value)) {
            // can also be array
            for (const name in ExperimentName) {
                logger.log(`name ${name}`)
                logger.log('binding.value.includes(name)', binding.value.includes(name))
                if (!binding.value.includes(name)) {
                    logger.log('array value not included, removing from DOM')
                    logger.log(`vnode ${vnode.elm}`)
                    el.parentElement.replaceChild(removeChild, el)
                }
            }
        } else {
            logger.error('experiment directive requires a string or array of strings')
        }
    },
}
