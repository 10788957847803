import { RouteConfig } from 'vue-router'
import { originationPagePaths } from '@/routes/originationRoutes'

// Todo we're keeping the old routes for backwards compatibility. We'll
//  need to consider when we can/should remove these routes, possibly
//  after the offers associated with old links expire.
//  https://app.asana.com/0/1127284905527130/1200626503538434/f
const routeNameOld: string = 'auth'
const routeName: string = 'a'

const pageNames = {
    EMPLOYER_EMAIL_VERIFICATION: 'employer_email_verification',
    TRUST_RETURN: 'trust',
    OFFER_RETURN: 'offer',
    INCOME_VERIFICATION_RETURN: 'verify_income',
    STATED_INCOME_RETURN: 'stated_income',
    PRE_QUALIFICATION_RETURN: 'prequal',
    // ============ OLD PAGES ============
    // Keeping both options for backward compatibility
    // Will eventually delete old pages below
    OFFER_RETURN_OLD: 'offer_return',
    INCOME_VERIFICATION_RETURN_OLD: 'income_verification_return',
    STATED_INCOME_RETURN_OLD: 'stated_income_return',
    PRE_QUALIFICATION_RETURN_OLD: 'pre_qualification_return',
}

export const authPagePaths = {
    RETURN_TO_ANYTHING: `/${routeName}/:returnToken`,
    // Todo The intention is to eventually replace everything below with the single path above
    EMPLOYER_EMAIL_VERIFICATION: `/${routeNameOld}/${pageNames.EMPLOYER_EMAIL_VERIFICATION}`,
    TRUST_RETURN: `/${routeNameOld}/${pageNames.TRUST_RETURN}`,
    TRUST_RETURN_PATH_PARAMETERIZED: `/${routeNameOld}/${pageNames.TRUST_RETURN}/:p`,
    // https://stackoverflow.com/a/47828416/858775
    OFFER_RETURN: `/${routeNameOld}/${pageNames.OFFER_RETURN}`,
    OFFER_RETURN_PATH_PARAMETERIZED: `/${routeNameOld}/${pageNames.OFFER_RETURN}/:p`,
    INCOME_VERIFICATION_RETURN: `/${routeNameOld}/${pageNames.INCOME_VERIFICATION_RETURN}`,
    INCOME_VERIFICATION_RETURN_PATH_PARAMETERIZED: `/${routeNameOld}/${pageNames.INCOME_VERIFICATION_RETURN}/:p`,
    STATED_INCOME_RETURN: `/${routeNameOld}/${pageNames.STATED_INCOME_RETURN}`,
    STATED_INCOME_RETURN_PATH_PARAMETERIZED: `/${routeNameOld}/${pageNames.STATED_INCOME_RETURN}/:p`,
    PRE_QUALIFICATION_RETURN: `/${routeNameOld}/${pageNames.PRE_QUALIFICATION_RETURN}`,
    PRE_QUALIFICATION_RETURN_PATH_PARAMETERIZED: `/${routeNameOld}/${pageNames.PRE_QUALIFICATION_RETURN}/:p`,
    // ============ OLD PATHS ============
    // Keeping both options for backward compatibility
    // Will eventually delete old paths below
    OFFER_RETURN_OLD: `/${routeNameOld}/${pageNames.OFFER_RETURN_OLD}`,
    OFFER_RETURN_OLD_PATH_PARAMETERIZED: `/${routeNameOld}/${pageNames.OFFER_RETURN_OLD}/:p`,
    INCOME_VERIFICATION_RETURN_OLD: `/${routeNameOld}/${pageNames.INCOME_VERIFICATION_RETURN_OLD}`,
    INCOME_VERIFICATION_RETURN_OLD_PATH_PARAMETERIZED: `/${routeNameOld}/${pageNames.INCOME_VERIFICATION_RETURN_OLD}/:p`,
    STATED_INCOME_RETURN_OLD: `/${routeNameOld}/${pageNames.STATED_INCOME_RETURN_OLD}`,
    STATED_INCOME_RETURN_OLD_PATH_PARAMETERIZED: `/${routeNameOld}/${pageNames.STATED_INCOME_RETURN_OLD}/:p`,
    PRE_QUALIFICATION_RETURN_OLD: `/${routeNameOld}/${pageNames.PRE_QUALIFICATION_RETURN_OLD}`,
    PRE_QUALIFICATION_RETURN_OLD_PATH_PARAMETERIZED: `/${routeNameOld}/${pageNames.PRE_QUALIFICATION_RETURN_OLD}/:p`,
}

export const authRoutes: RouteConfig[] = [
    {
        path: authPagePaths.RETURN_TO_ANYTHING,
        component: () => import(/* webpackChunkName: "origination-return-to-anything" */ '@/pages/auth/OriginationReturnToAnything.vue'),
        meta: { public: true },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: authPagePaths.EMPLOYER_EMAIL_VERIFICATION,
        component: () => import(/* webpackChunkName: "confirm-employer-email" */ '@/pages/auth/EmployerEmailConfirm.vue'),
        meta: { public: true },
    },
    {
        path: authPagePaths.TRUST_RETURN,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/auth/OriginationReturn.vue'),
        meta: { public: true },
        props: { returnPagePath: originationPagePaths.TRUST_INFO_SUBMISSION },
    },
    {
        path: authPagePaths.TRUST_RETURN_PATH_PARAMETERIZED,
        redirect: (to) => {
            return { path: authPagePaths.TRUST_RETURN, query: { p: to.params.p } }
        },
    },
    {
        path: authPagePaths.OFFER_RETURN,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/auth/OriginationReturn.vue'),
        meta: { public: true },
        props: { returnPagePath: originationPagePaths.OFFER_PREVIEW },
    },
    {
        path: authPagePaths.OFFER_RETURN_PATH_PARAMETERIZED,
        redirect: (to) => {
            return { path: authPagePaths.OFFER_RETURN, query: { p: to.params.p } }
        },
    },
    {
        path: authPagePaths.INCOME_VERIFICATION_RETURN,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/auth/OriginationReturn.vue'),
        meta: { public: true },
        props: { returnPagePath: originationPagePaths.EMPLOYMENT_INCOME_VERIFICATION },
    },
    {
        path: authPagePaths.INCOME_VERIFICATION_RETURN_PATH_PARAMETERIZED,
        redirect: (to) => {
            return { path: authPagePaths.INCOME_VERIFICATION_RETURN, query: { p: to.params.p } }
        },
    },
    {
        path: authPagePaths.STATED_INCOME_RETURN,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/auth/OriginationReturn.vue'),
        meta: { public: true },
        props: { returnPagePath: originationPagePaths.STATED_INCOME },
    },
    {
        path: authPagePaths.STATED_INCOME_RETURN_PATH_PARAMETERIZED,
        redirect: (to) => {
            return { path: authPagePaths.STATED_INCOME_RETURN, query: { p: to.params.p } }
        },
    },
    {
        path: authPagePaths.PRE_QUALIFICATION_RETURN,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/auth/OriginationReturn.vue'),
        meta: { public: true },
        props: { returnPagePath: originationPagePaths.PRE_QUALIFICATION },
    },
    {
        path: authPagePaths.PRE_QUALIFICATION_RETURN_PATH_PARAMETERIZED,
        redirect: (to) => {
            return { path: authPagePaths.PRE_QUALIFICATION_RETURN, query: { p: to.params.p } }
        },
    },
    // ============ OLD ROUTES ============
    // Keeping both options for backward compatibility
    // Will eventually delete old pages below
    {
        path: authPagePaths.OFFER_RETURN_OLD,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/auth/OriginationReturn.vue'),
        meta: { public: true },
        props: { returnPagePath: originationPagePaths.OFFER_PREVIEW },
    },
    {
        path: authPagePaths.OFFER_RETURN_OLD_PATH_PARAMETERIZED,
        redirect: (to) => {
            return { path: authPagePaths.OFFER_RETURN_OLD, query: { p: to.params.p } }
        },
    },
    {
        path: authPagePaths.INCOME_VERIFICATION_RETURN_OLD,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/auth/OriginationReturn.vue'),
        meta: { public: true },
        props: { returnPagePath: originationPagePaths.SELECT_INCOME_VERIFICATION_METHOD },
    },
    {
        path: authPagePaths.INCOME_VERIFICATION_RETURN_OLD_PATH_PARAMETERIZED,
        redirect: (to) => {
            return { path: authPagePaths.INCOME_VERIFICATION_RETURN_OLD, query: { p: to.params.p } }
        },
    },
    {
        path: authPagePaths.STATED_INCOME_RETURN_OLD,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/auth/OriginationReturn.vue'),
        meta: { public: true },
        props: { returnPagePath: originationPagePaths.STATED_INCOME },
    },
    {
        path: authPagePaths.STATED_INCOME_RETURN_OLD_PATH_PARAMETERIZED,
        redirect: (to) => {
            return { path: authPagePaths.STATED_INCOME_RETURN_OLD, query: { p: to.params.p } }
        },
    },
    {
        path: authPagePaths.PRE_QUALIFICATION_RETURN_OLD,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/auth/OriginationReturn.vue'),
        meta: { public: true },
        props: { returnPagePath: originationPagePaths.PRE_QUALIFICATION },
    },
    {
        path: authPagePaths.PRE_QUALIFICATION_RETURN_OLD_PATH_PARAMETERIZED,
        redirect: (to) => {
            return { path: authPagePaths.PRE_QUALIFICATION_RETURN_OLD, query: { p: to.params.p } }
        },
    },
]
