import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { inspect, logger } from '@/utils/logger'
import { isSafariPrivateBrowsing } from '@/utils/parseUserAgents'
import router from '@/routes/router'
import { sharedPagePaths } from '@/routes/sharedRoutes'

const timeout = process.env.VUE_APP_NODE_ENV === 'production' ? 40000 : Number(appSessionStorage.getItem(localStorageKey.httpTimeout) ?? 20000) // Some browsers have 10 sec timeouts, simulate this in the dev env with potential override
console.log(`HTTP timeout set to ${timeout}`)
const config = {
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
    timeout,
}

const axiosInstance = axios.create(config)
const coApplicantAxiosInstance = axios.create(config)

const authInterceptor = (request: any) => {
    /** add auth token */
    let accessToken
    const jwtTokenJson = appSessionStorage.getItem(localStorageKey.jwtTokens)
    try {
        if (jwtTokenJson) {
            accessToken = JSON.parse(jwtTokenJson).accessJWT
        }
    } catch (error) {
        logger.error(`authInterceptor request: ${request.url} jwt json: ${jwtTokenJson}`, null /* event */, error)
    }

    if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`
    }
    // Custom header for SessionJWT Authentication strategy
    const sessionAccessToken = appSessionStorage.getItem(localStorageKey.sessionAccessJWT)
    if (sessionAccessToken) {
        request.headers.SessionAuthorization = `Bearer ${sessionAccessToken}`
    }

    return request
}

const coApplicantAuthInterceptor = (request: any) => {
    /** add auth token */
    let accessToken
    const jwtTokenJson = appSessionStorage.getItem(localStorageKey.coApplicantJwtTokens)
    try {
        if (jwtTokenJson) {
            accessToken = JSON.parse(jwtTokenJson).accessJWT
        }
    } catch (error) {
        logger.error(`co applicant authInterceptor request: ${request.url} jwt json: ${jwtTokenJson}`, null /* event */, error)
    }

    if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`
    }
    // Custom header for SessionJWT Authentication strategy
    const sessionAccessToken = appSessionStorage.getItem(localStorageKey.sessionAccessJWT)
    if (sessionAccessToken) {
        request.headers.SessionAuthorization = `Bearer ${sessionAccessToken}`
    }

    return request
}

/** Adding the request interceptors */
axiosInstance.interceptors.request.use(authInterceptor)
coApplicantAxiosInstance.interceptors.request.use(coApplicantAuthInterceptor)

const windowSizeInMsec = 1000 * 10
let windowStartTime = new Date()
let callDurationsDuringWindow: number[] = []
let erroredCallsDuringWindow = 0
let totalCallsDuringWindow = 0

const printAndResetNetworkStats = () => {
    if (totalCallsDuringWindow > 0) {
        if (new Date().getTime() - windowStartTime.getTime() > windowSizeInMsec) {
            const minCallDuration = Math.min(...callDurationsDuringWindow)
            const maxCallDuration = Math.max(...callDurationsDuringWindow)
            const averageCallDuration = Math.round(callDurationsDuringWindow.reduce((a, b) => a + b, 0) / callDurationsDuringWindow.length)
            const stdDeviation = Math.round(getStandardDeviation(callDurationsDuringWindow))

            logger.log(`Network stats over last ${windowSizeInMsec} msec: / Attempted network calls: ${totalCallsDuringWindow} / Errored network calls: ${erroredCallsDuringWindow}`)
            logger.log(`Min / max / avg / std-dev call duration: ${minCallDuration} msec / ${maxCallDuration} msec / ${averageCallDuration} msec / ${stdDeviation} msec`)

            // Reset window parameter to initial values
            totalCallsDuringWindow = 0
            erroredCallsDuringWindow = 0
            callDurationsDuringWindow = []
            windowStartTime = new Date()
        }
    }
}
setInterval(printAndResetNetworkStats, windowSizeInMsec)

// https://stackoverflow.com/a/53577159/858775
function getStandardDeviation(array: number[]) {
    const n = array?.length || 0
    if (n === 0 || n === 1) {
        return 0
    }

    const mean = array.reduce((a, b) => a + b) / n
    return Math.sqrt(array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
}

class HttpClient {
    private readonly axiosInstance: AxiosInstance

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance
    }

    private handleError = async (e: any) => {
        // HTTP 423 === 'Locked', we use this as an expected status for waiting on things to complete
        // Don't count that against out errored calls count
        if (e?.response?.status !== 423) {
            erroredCallsDuringWindow++
        }
        if (!appSessionStorage.getItem(localStorageKey.sessionAccessJWT) && isSafariPrivateBrowsing()) {
            await router.push({ path: sharedPagePaths.THANKS, query: { reason: 'privateBrowsing' } })
            return
        }
        throw e
    }

    get = async (path: string, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
        try {
            const startTime = new Date()
            totalCallsDuringWindow++
            const res = await this.axiosInstance.get(path, config)

            const durationMsec = new Date().getTime() - startTime.getTime()
            callDurationsDuringWindow.push(durationMsec)

            return res
        } catch (e) {
            await this.handleError(e)
            return Promise.reject()
        }
    }

    post = async (path: string, data?: any, config: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
        try {
            const startTime = new Date()
            totalCallsDuringWindow++
            const res = await this.axiosInstance.post(path, data, config)

            const durationMsec = new Date().getTime() - startTime.getTime()
            callDurationsDuringWindow.push(durationMsec)

            return res
        } catch (e) {
            await this.handleError(e)
            return Promise.reject()
        }
    }
}

const httpClient = new HttpClient(axiosInstance)
const coApplicantHttpClient = new HttpClient(coApplicantAxiosInstance)

const RETRY_INTERVAL_MSEC = 6000

const runWithRetryLogic = async <T>(requestFunc: () => Promise<T>, maxRetryCount: number, customRetryInterval?: number, errHandler?: (exception: any) => {}): Promise<T> =>
    new Promise((resolve, reject) => {
        const retryInterval = customRetryInterval || RETRY_INTERVAL_MSEC

        let attemptNumber = 0
        const retryAndLog = async () => {
            try {
                const ret = await requestFunc()
                return resolve(ret)
            } catch (error) {
                const allowRetry = error.response?.status === undefined || error.response?.status === 0 || error.code === 'ECONNABORTED' || error.code === 'ECONNRESET'
                if (!allowRetry) {
                    return reject(error)
                }

                logger.log(`Retry attempt ${attemptNumber}/${maxRetryCount} failed due to error: ${inspect(error)}`)
                attemptNumber++
                if (attemptNumber > maxRetryCount) {
                    return reject(new Error(`Max retry limit of ${maxRetryCount} exceeded with error: ${inspect(error)}`))
                }
                logger.log(`Next retry attempt in ${retryInterval} ms`)

                try {
                    if (errHandler) {
                        logger.log(`Calling error handler`)
                        await errHandler(error)
                    }
                } catch (e) {
                    logger.fatal(`Error handler failed`, null /* event */, e)
                }

                setTimeout(retryAndLog, retryInterval)
            }
        }
        retryAndLog()
    })

export { httpClient, coApplicantHttpClient, runWithRetryLogic }

window.logEvent = function (eventName: string, properties?: object) {
    const sessionId = appSessionStorage.getItem(localStorageKey.sessionId)
    const experimentName = appSessionStorage.getItem(localStorageKey.experimentName)

    const body = {
        eventName,
        properties: { ...properties, currentPath: window.location.pathname, previousPath: window.previousPath, experimentName },
        sessionId,
    }

    runWithRetryLogic(async () => await httpClient.post('/ana/evnt', body), 2).catch((e) => {
        logger.fatal(`analytics event failed! Note that this does not impact the users experience`, null /* event */, e)
    })
}
