<template>
    <div id="app">
        <router-view :key="$route.path" />
    </div>
</template>

<script>
    import Vue from 'vue'
    import { logger } from '@/utils/logger'
    import { i18n } from './utils/i18n'
    import { setLocalMetadata } from '@/services/marketing'
    import { runWithRetryLogic } from '@/utils/http-client'

    export default Vue.extend({
        name: 'App',
        metaInfo: {
            titleTemplate: (titleChunk) => {
                // If undefined or blank then we don't need the hyphen
                return titleChunk ? `${titleChunk} | Aven Crypto Card` : 'Aven Crypto Card: A Credit Card backed by Crypto Assets'
            },
            meta: [
                {
                    name: 'description',
                    vmid: 'description',
                    content: 'Unlock the power of home equity to get insanely low credit card rates. Stop worrying about promo rates expiring. Save every single month, forever.',
                },
            ],
        },
        created() {
            // Set Default Cookie Settings
            Vue.$cookies.config('30d', '', `${process.env.VUE_APP_COOKIES_URL}`, true, 'Strict')

            // Set Locale for Language
            let locale = 'en'
            if (this.$cookies.isKey('locale')) {
                locale = this.$cookies.get('locale')
            } else {
                if (navigator.userLanguage) {
                    locale = navigator.userLanguage.includes('es') ? 'es' : 'en'
                } else if (navigator.language) {
                    locale = navigator.language.includes('es') ? 'es' : 'en'
                } else {
                    logger.info('Unable to determine user langauge from javascript, defaulting to "en"')
                }
            }
            i18n.locale = locale
            this.$cookies.set('locale', locale)
        },
        mounted() {
            runWithRetryLogic(setLocalMetadata, 2)
        },
    })
</script>

<style lang="scss">
    @import 'styles/main.scss';
</style>
